import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  formContainer: {
    padding: '20px',
    backgroundColor: '#101010',
    borderRadius: '8px',
    boxShadow: '0px 0px 20px rgba(255, 215, 0, 0.3)',
    color: '#F5C892',
    marginTop: '20px',
    marginBottom: '20px',
    '@media (min-width:600px)': {
      padding: '40px',
      marginTop: '40px',
      marginBottom: '40px',
    },
  },
  mainTitle: {
    marginBottom: '10px',
    fontWeight: 'bold',
    color: '#F5C892',
    fontSize: '2rem',
    textAlign: 'center',
    '@media (min-width:600px)': {
      fontSize: '2.5rem',
      marginBottom: '20px',
    },
  },
  description: {
    color: '#B6A57D',
    fontSize: '1rem',
    textAlign: 'center',
    marginBottom: '20px',
    '@media (min-width:600px)': {
      fontSize: '1.2rem',
      marginBottom: '30px',
    },
  },
  sectionTitle: {
    margin: '20px 0 10px',
    fontWeight: 'bold',
    color: '#F5C892',
    fontSize: '1.5rem',
    textAlign: 'center',
    '@media (min-width:600px)': {
      margin: '40px 0 20px',
      fontSize: '1.8rem',
    },
  },
  formField: {
    marginBottom: '15px',
    '& label': {
      color: '#B6A57D',
    },
    '& label.Mui-focused': {
      color: '#F5C892',
    },
    '& input': {
      color: '#F5C892',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#B6A57D',
      },
      '&:hover fieldset': {
        borderColor: '#F5C892',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#F5C892',
      },
    },
    '& .MuiSelect-icon': {
      color: '#F5C892',
    },
    '& .MuiSelect-root': {
      color: '#F5C892 !important', // Force dropdown text to remain gold
    },
    '& .MuiSelect-select': {
      color: '#F5C892 !important', // Ensures the selected item text is gold
      backgroundColor: 'transparent', // Avoid unwanted background
    },
    '& .MuiMenuItem-root': {
      color: '#F5C892', // Dropdown menu items in gold
      backgroundColor: '#101010', // Match dropdown background to form
      '&:hover': {
        backgroundColor: '#1C1C1C', // Slightly lighter on hover
      },
    },
    '@media (min-width:600px)': {
      marginBottom: '20px',
    },
  },
  
  
  
  checkbox: {
    color: '#F5C892', // Checkbox color
    '&.Mui-checked': {
      color: '#F5C892 !important',
    },
    '& .MuiSvgIcon-root': {
      border: '1px solid #B6A57D', // Add an outline to make the checkbox visible
      borderRadius: '4px',
    },
  },
  captchaBox: {
    backgroundColor: '#1C1C1C',
    padding: '10px',
    borderRadius: '8px',
    marginTop: '20px',
    textAlign: 'center',
    boxShadow: '0px 0px 10px rgba(255, 215, 0, 0.2)',
    '@media (min-width:600px)': {
      padding: '15px',
    },
  },
  captchaText: {
    color: '#F5C892',
    fontSize: '1rem',
    marginBottom: '10px',
    '@media (min-width:600px)': {
      fontSize: '1.2rem',
    },
  },
  captchaInput: {
    maxWidth: '120px',
    margin: '0 auto',
    '& label': {
      color: '#FFFFFF', // White text for the label
    },
    '& label.Mui-focused': {
      color: '#F5C892', // Gold text when the label is focused
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#B6A57D',
      },
      '&:hover fieldset': {
        borderColor: '#F5C892',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#F5C892',
      },
    },
    '& input': {
      color: '#FFFFFF', // White text for input visibility
    },
    '@media (min-width:600px)': {
      maxWidth: '150px',
    },
  },
  
  errorMessage: {
    color: '#FF6B6B',
    marginTop: '10px',
  },
  submitButton: {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#C49F35 !important',
    color: '#101010',
    fontSize: '1rem',
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: '#B8860B',
    },
    '&:disabled': {
      backgroundColor: '#555',
      color: '#aaa',
    },
    '@media (min-width:600px)': {
      marginTop: '30px',
      padding: '15px 30px',
      fontSize: '1.2rem',
    },
  },
  signatureCanvas: {
    border: '2px solid #F5C892',
    borderRadius: '4px',
    marginBottom: '20px',
  },
  signatureActions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
    '& button': {
      marginTop: '10px',
    },
    '@media (min-width:600px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  signatureButton: {
    marginTop: '10px',
    color: '#F5C892',
    borderColor: '#F5C892',
    '&:hover': {
      borderColor: '#B8860B',
    },
  },
}));

export default useStyles;
